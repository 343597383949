<template>
  <div class="jkVideo" :class="{gifMode}" @click="play">
    <!-- eslint-disable max-len -->
    <video
      ref="video"
      disablePictureInPicture
      controlsList="nodownload noremoteplayback"
      playsinline
      :controls="!gifMode && running"
      :poster="poster"

      :autoplay="gifMode && autoplay"
      :loop="true"
      :muted="true"
    >
      <source :src="src" :type="type">
    </video>

    <transition name="appear">
      <Play v-if="!gifMode && !running" />
    </transition>

  </div>
</template>

<script>
import Play from '@/assets/images/play.svg';

export default {
  components: { Play },
  props: {
    poster: { type: String, default: null },
    src: { type: String, default: null },
    type: { type: String, default: null },
    gifMode: { type: Boolean, default: false },
    autoplay: { type: Boolean, default: false },
  },
  data: () => ({
    running: false,
  }),
  mounted() {
    this.$refs.video.addEventListener('play', () => { this.running = true; });
    this.$refs.video.addEventListener('ended', () => { this.running = false; });
    this.$nextTick(() => {
      if (this.$refs.video) {
        this.$refs.video.setAttribute('muted', 'true');
      }
    });
  },
  methods: {
    play() {
      return this.$refs.video.play();
    },
    async stop() {
      await this.$refs.video.pause();
      this.$refs.video.currentTime = 0;
    },
    async reset() {
      await this.$refs.video.pause();
      this.$refs.video.currentTime = 0;
      return this.$refs.video.play();
    },
  },
};
</script>

<style lang="scss" scoped>
.jkVideo {
    color: var(--color-violet);
    transition: color .3s ease;

    &:not(.gifMode) {
      cursor: pointer;
    }

    &:hover {
        color: var(--color-yellow);
    }

    video {
      width: 100%;
      min-height: 100%;
      max-height: 100%;
    }
}

svg {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 12%;
  transform: translate(-50%, -50%);
  cursor: pointer;
  filter: drop-shadow(0 0 1rem black);
}
</style>
