import { nextTick, ref } from 'vue';
import { useGtm } from '@gtm-support/vue-gtm';

// eslint-disable-next-line import/prefer-default-export
export function useHistory(options) {
  const from = ref(null);
  const to = ref(null);
  const campaignPopup = ref(null);
  const gtm = useGtm();

  const getCampaignLink = (result = false) => {
    let path = options.campaign.link;
    if (result) {
      const pathWithoutLang = options.campaign.link.substring(3);
      path = `/de/gewinner${pathWithoutLang}`;
    }

    return path;
  };

  const historyOpen = (result = false) => {
    const path = getCampaignLink(result);

    window.history.pushState({
      name: 'campaign',
      type: 'popup',
    }, undefined, path);
    gtm.trackView(options.campaign.title, path);

    options.active.value = true;
    setTimeout(() => {
      if (from.value && to.value) {
        options.animate(from.value, to.value);
      }
      options.background.value = true;
      campaignPopup.value.classList.add('active');
    }, 10);

    setTimeout(() => {
      options.lock();
      window.scrollTo(0, 0);
    }, 50);
  };

  const historyClose = (result = false) => {
    const path = result ? '/gewinner' : '/';

    window.history.pushState({
      name: 'campaign',
      type: 'page',
    }, undefined, path);
    gtm.trackView('Dooh Award', path);

    if (from.value && to.value) {
      options.animate(to.value, from.value, {
        from: { border: 0, y: window.lockedOn },
        to: { border: 0, y: window.lockedOn },
      });
    }
    options.unlock();

    nextTick(() => {
      options.background.value = false;
      campaignPopup.value.classList.remove('active');
    });
    setTimeout(() => {
      options.active.value = false;
    }, 500);
  };

  return {
    to,
    from,
    campaignPopup,
    getCampaignLink,
    historyOpen,
    historyClose,
  };
}
