<template>
  <a
    :href="getCampaignLink(result)"
    :title="campaign.title"
    class="campaign-container"
    :style="{ '--color-secondary': 'var(--color-' + (isCharity ? 'blue-dark' : 'violet-medium') + ')' }"
    @click.prevent="historyOpen(result)"
    @mouseover="hover = true"
    @mouseleave="hover = false"
  >
    <div
      class="campaign"
      :class="{'favorite': isFavorite }"
    >
      <div v-if="!result" class="heart" @click.stop.prevent="store.commit('TOGGLE_FAVORITE', campaign)">
        <transition is="div" mode="out-in" name="scale">
          <Heart v-if="!hover && !isFavorite" />
          <HeartFull v-else />
        </transition>
      </div>
      <div ref="from" :class="{ small }" class="campaign-media shade">
        <div class="aspect a916 center">
          <transition name="campaign-appear">
            <img
              :src="campaign.bild.normal"
              :alt="campaign.title"
            >
          </transition>
        </div>
        <div class="aspect a916 center">
          <jk-video
            v-if="campaign.video && hover"
            ref="video"
            :autoplay="false"
            :src="campaign.video.link"
            :poster="campaign.bild.normal"
            :gif-mode="true"
            type="video/mp4"
          />
        </div>
      </div>
      <div class="w-full h-12 flex items-center">
        <strong class="text-active" v-text="campaign.title" />
      </div>
    </div>

    <teleport v-if="active" to="#overlay">
      <div
        ref="backdrop"
        class="backdrop"
        :class="{ background }"
      />
      <div ref="campaignPopup" class="popup">
        <div class="constrain pt-8">
          <div class="close " @click="historyClose(result)">
            <Close class="w-8 h-8 cursor-pointer text-violet ml-auto" />
          </div>

          <div class="grid grid-cols-1 sm:grid-cols-2 gap-8">
            <div class="w-full sm:w-4/5 mr-auto flex justify-center media p-8">
              <div ref="to" class="aspect a916 w-full">
                <jk-video
                  v-if="campaign.video != null"
                  :src="campaign.video.link"
                  type="video/mp4"
                  autoplay
                  gif-mode
                />
                <img
                  v-else
                  :src="campaign.bild.normal"
                  :alt="campaign.title"
                >
              </div>
            </div>
            <div class="content flex items-center justify-center flex-col">
              <div v-if="!result" class="bg-violet w-full p-4 flex items-center justify-center">
                <div class="cursor-pointer" @click.stop.prevent="store.commit('TOGGLE_FAVORITE', campaign)">
                  <transition is="div" mode="out-in" name="scale">
                    <Heart v-if="!isFavorite" class="text-white h-8 w-8" />
                    <HeartFull v-else class="text-white h-8 w-8" />
                  </transition>
                </div>
              </div>
              <div class="bg-black p-4 text-white">
                <h3 v-text="campaign.title" />
                <div v-html="campaign.text" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </teleport>
  </a>
</template>

<script>
import {
  computed,
  onMounted, ref, watchEffect,
} from 'vue';
import { useRoute } from 'vue-router';
import { useStore } from 'vuex';
import useScrolllock from '@/composables/scrolllock';
import useMorph from '@/composables/morph';
import JkVideo from '@/components/utils/jkVideo.vue';
import { useHistory } from '@/composables/history';
import HeartFull from '@/assets/images/heart_active.svg';
import Close from '@/assets/images/close.svg';
import Heart from '@/assets/images/heart.svg';

export default {
  name: 'Campaign',
  components: {
    JkVideo, Close, Heart, HeartFull,
  },
  props: {
    campaign: {
      type: Object,
      required: true,
    },
    small: {
      type: Boolean,
      default: false,
    },
    result: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const active = ref(false);
    const background = ref(false);
    const hover = ref(false);
    const video = ref(null);
    const store = useStore();
    const { lock, unlock } = useScrolllock();
    const { animate } = useMorph();
    const route = useRoute();
    const {
      historyOpen,
      historyClose,
      getCampaignLink,
      to,
      from,
      campaignPopup,
    } = useHistory({
      campaign: props.campaign,
      active,
      lock,
      unlock,
      animate,
      background,
    });

    const isFavorite = computed(() => {
      if (Array.isArray(props.campaign.charity) && props.campaign.charity.length) {
        return store.state.favoriteCharityCampaigns.find((c) => c.id === props.campaign.id) != null;
      }

      return store.state.favoriteCampaigns.find((c) => c.id === props.campaign.id) != null;
    });

    const isCharity = computed(() => Array.isArray(props.campaign.charity) && props.campaign.charity.length);

    onMounted(() => {
      let currentPath = route.path;
      if (props.result) {
        currentPath = route.path.replace('/gewinner', '');
      }
      if (currentPath === props.campaign.link) {
        historyOpen(props.result);
      }
    });

    watchEffect(() => {
      if (video.value) {
        if (hover.value) {
          video.value.reset();
        } else {
          video.value.stop();
        }
      }
    });

    return {
      store,
      lock,
      isFavorite,
      isCharity,
      unlock,
      animate,
      active,
      background,
      campaignPopup,
      historyOpen,
      historyClose,
      getCampaignLink,
      to,
      from,
      hover,
      video,
    };
  },
  methods: {
    enter(fromRight = false) {
      this.active = true;

      this.$nextTick(() => {
        this.$refs.backdrop.style.transition = 'none';
        this.$refs.backdrop.style.transform = 'translateX(-100%)';
        this.$refs.campaignPopup.style.transition = 'none';
        this.$refs.campaignPopup.style.transform = 'translateX(-100%)';
      });

      setTimeout(() => {
        this.$refs.backdrop.style.transition = '.75s ease-in';
        this.$refs.backdrop.style.transform = '';
      }, 1);

      setTimeout(() => {
        this.background = true;
        this.$refs.campaignPopup.style.transition = '';
        this.$refs.campaignPopup.style.transform = '';
        this.$refs.backdrop.style.transition = '';
      }, 300);

      setTimeout(() => {
        window.scrollTo(0, 0);
      }, 700);
    },
  },
};
</script>

<style lang="scss" scoped>
.campaign-container {
  position: relative;
  display: block;

  &::before {
    content: "";
    display: block;
    padding-top: 180%;
  }

  & > *:first-child {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  .campaign {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .heart {
    @apply absolute top-0 right-0 w-8 h-8;
  }

  .favorite .heart {
    color: var(--color-active);
  }

  .campaign-media {
    will-change: auto;
    width: 80%;
    margin-top: auto;
  }
}

.backdrop {
  position: fixed;
  z-index: 155;
  top: -30vh;
  left: 0;
  width: 100%;
  height: 160vh;
  transform: translateX(-100%);
  will-change: transform;
  background: linear-gradient(to bottom, var(--color-violet-medium) 75%, var(--color-violet-dark));
  transition: transform .5s ease;

  &.background {
    z-index: 145;
    transform: translateX(0);
  }
}

.popup {
  position: absolute;
  z-index: 150;
  top: 0;
  left: 0;
  width: 100%;
  overflow-x: hidden;
  transform: translateX(0);
  transition: transform 5s ease-in-out;

  .close {
    z-index: 150;
    position: relative;
    padding-bottom: 40px;

    @screen sm {
      padding-top: 40px;
    }
  }

  .media {
    border: 1px solid transparent;
    transition: border .5s ease .25s;
  }

  .content {
    opacity: 0;
    transition: opacity .5s ease .25s;
  }

  &.active {
    .media {
      border: 1px solid var(--color-violet);
    }
    .content {
      opacity: 1;
      transition: opacity .5s ease;
    }
  }
}

</style>
