export default function Morph() {
  function animate(from, to, offset) {
    // get parent
    from = (from instanceof HTMLElement) ? from : from.$el;
    to = (to instanceof HTMLElement) ? to : to.$el;

    // defaults
    offset = {
      from: { border: 0, y: 0 },
      to: { border: 0, y: 0 },
      ...offset,
    };

    // PREPARE TARGET
    to.style.transitionDuration = '0s';
    to.style.opacity = 0;

    // CLONING
    const oBounds = from.getBoundingClientRect();
    const clone = from.cloneNode(true);

    // move clone to start position
    clone.classList.add('clone');
    clone.style.top = `${oBounds.top + offset.from.border + offset.from.y}px`;
    clone.style.left = `${oBounds.left + offset.from.border}px`;
    clone.style.width = `${oBounds.width - offset.from.border * 2}px`;
    clone.style.height = `${oBounds.height - offset.from.border * 2}px`;

    // remove lazy loading for firefox
    const img = clone.querySelector('img');
    if (img) img.setAttribute('loading', 'eager');
    const video = clone.querySelector('video');
    if (video) video.removeAttribute('controls');

    document.body.appendChild(clone);

    from.style.transitionDuration = '0s';

    setTimeout(() => {
      from.style.opacity = 0;

      const tBounds = to.getBoundingClientRect();
      clone.style.top = `${tBounds.top + offset.to.border + offset.to.y}px`;
      clone.style.left = `${tBounds.left + offset.to.border}px`;
      clone.style.width = `${tBounds.width - offset.to.border * 2}px`;
      clone.style.height = `${tBounds.height - offset.to.border * 2}px`;

      setTimeout(() => {
        to.style.opacity = '';
        setTimeout(() => { to.style.transitionDuration = ''; }, 50);
        from.style.opacity = '';
        clone.remove();
      }, 750);
    }, 50);
  }

  return {
    animate,
  };
}
