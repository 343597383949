<template>
  <div class="relative">
    <div class="constrain h-screen flex flex-col justify-center">
      <h1 class="z-10">
        Voting Digital
        <span class="sm:whitespace-nowrap">Out-of-Home-</span>Award
        <span class="text-white">2021</span>
      </h1>
      <div class="z-10 flex justify-between items-end">
        <h2 class="font-bold text-white mt-8">
          {{ t('hero.slogan') }}
        </h2>
        <Go to="#dooh-award" class="z-10">
          <ArrowDown class="text-violet h-8" />
        </Go>
      </div>
      <div class="absolute top-0 h-full left-0 w-full z-0 opacity-60">
        <div ref="waveContainer" class="h-full" />
      </div>
    </div>
  </div>
</template>

<script>
import { useI18n } from 'vue-i18n';
import lottie from 'lottie-web';
import { onBeforeUnmount, onMounted, ref } from 'vue';
import ArrowDown from '@/assets/images/arrow_down.svg';

export default {
  name: 'Hero',
  components: { ArrowDown },
  setup() {
    const { t } = useI18n();
    const waveContainer = ref(null);
    const animation = ref(null);

    onMounted(() => {
      animation.value = lottie.loadAnimation({
        container: waveContainer.value,
        path: '/animations/wave.json',
        rendererSettings: {
          preserveAspectRatio: 'xMidYMid slice',
        },
      });
    });

    onBeforeUnmount(() => {
      animation.value.destroy();
    });

    return { t, waveContainer, animation };
  },
};
</script>

<style lang="scss" scoped>
</style>
