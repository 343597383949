<template>
  <div class="mt-8 sm:mt-0">
    <Hero id="hero" />
    <div id="dooh-award" class="constrain">
      <section v-if="dooh.length" v-view class="py-28">
        <h1 class="text-xl sm:text-1_7xl sm:mb-16">
          Digital Out of Home Award
        </h1>
        <div class="constrain grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-8">
          <Campaign
            v-for="campaign in dooh"
            :key="campaign.id"
            :campaign="campaign.existierendekampagne != null ? campaign.existierendekampagne.data : campaign"
            result
          />
        </div>
      </section>
      <section v-if="charity.length" v-view class="pb-28">
        <h1 class="text-xl sm:text-1_7xl sm:mb-16">
          Digital Out of Home Charity Award
        </h1>
        <div class="constrain grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-8">
          <Campaign
            v-for="campaign in charity"
            :key="campaign.id"
            :campaign="campaign.existierendekampagne != null ? campaign.existierendekampagne.data : campaign"
            result
          />
        </div>
      </section>
      <section v-if="rookie.length" v-view class="pb-28">
        <h1 class="text-xl sm:text-1_7xl sm:mb-16">
          Digital Out of Home Rookie Award
        </h1>
        <div class="constrain grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-8">
          <Campaign
            v-for="campaign in rookie"
            :key="campaign.id"
            :campaign="campaign.existierendekampagne != null ? campaign.existierendekampagne.data : campaign"
            result
          />
        </div>
      </section>
    </div>
  </div>
</template>

<script lang="ts">
import { onMounted, ref, computed } from 'vue';
import { useStore } from 'vuex';
import { useI18n } from 'vue-i18n';
import Hero from '../parts/Hero.vue';
import Campaign from '../parts/Campaign.vue';

export default {
  name: 'ResultPage',
  components: {
    Hero,
    Campaign,
  },
  setup() {
    const store = useStore();
    const { t } = useI18n();

    onMounted(() => {
      store.dispatch('FETCH_RESULTS');
    });

    const results = computed(() => store.state.results);
    const dooh = computed(
      () => store.state.results.filter((c) => c.kategorie.length === 0 || c.kategorie.includes('0')),
    );
    const charity = computed(
      () => store.state.results.filter((c) => c.kategorie.includes('1')),
    );
    const rookie = computed(
      () => store.state.results.filter((c) => c.kategorie.includes('2')),
    );

    return {
      store,
      t,
      results,
      dooh,
      charity,
      rookie,
    };
  },
};
</script>
